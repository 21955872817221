import React from "react";

interface ButtonProps {
  variant: "default" | "transparent";
  iconPosition?: "start" | "end";
  icon?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  bg?: string;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  variant,
  iconPosition,
  icon,
  children,
  onClick,
  bg,
  className,
}) => {
  const baseStyle =
    "px-5 py-3 max-h-[3rem] rounded-lg flex items-center text-base font-medium leading-4  font-inter";

  const variantStyle =
    variant === "default"
      ? `${
          bg === "black" ? "bg-black" : "bg-green-500 hover:bg-green-600"
        } text-white`
      : "bg-transparent text-grey-600 border-[1px] border-transparent hover:border-[#E5EAF0] hover:bg-[#F6F9FF] ";

  return (
    <button
      className={`${baseStyle} ${variantStyle} ${className}`}
      onClick={onClick}
    >
      {iconPosition === "start" && icon && <span className="mr-3">{icon}</span>}
      {children}
      {iconPosition === "end" && icon && <span className="ml-3">{icon}</span>}
    </button>
  );
};

export default Button;
