import React from "react";
import Chip from "./common/Chip";
import EfficiencyTitels from "./common/EfficiencyTitels";
import forcasting from "../assets/forcasting.png";
import tractActivity from "../assets/tractActivity.png";
import qr from "../assets/qr.png";
import spreadSheet from "../assets/spreadSheet.png";
import activityOrder from "../assets/activityOrders.png";

const Efficiency = () => {
  return (
    <div className="container " id="solutions">
      <div className="mt-40">
        <div className="flex  flex-col justify-center text-center">
          <h1 className="md:text-4xl text-lg  lg:leading-9 leading-6 lg:font-medium font-semibold  text-center text-gray-900  font-inter">
            Unleash the Future of Efficiency
          </h1>
          <p className="lg:text-lg text-xs font-normal text-center lg:mt-6 mt-4 leading-7 text-gray-800 font-inter">
            Discover the strategic advantage Emerald brings to propel your
            organization into the future
          </p>
        </div>

        <div className="flex md:flex-nowrap flex-wrap md:gap-[18px] gap-4  md:mt-20 mt-12 ">
          <div className="">
            <div className="bg-black md:p-9 p-5 rounded-xl">
              <Chip text="Analytics for Better Outcomes" bgColor="white" />
              <div className="md:mt-7 mt-4 ">
                <EfficiencyTitels
                  title="Plan with Accurate Forecasting"
                  desc="Emerald revolutionizes your planning with advanced forecasting tools. Anticipate needs, schedule proactively, and optimize resources to keep operations running smoothly."
                  isWhite={true}
                  marginTopMobile="mt-6"
                  marginTopDesktop="md:mt-4"
                  maxWidthH2="lg:max-w-[20rem]"
                  maxWidtMobilehH2="max-w-[9rem]"
                />
              </div>
              <div className="md:mt-20 mt-7">
                <img src={forcasting} alt="forcasting" className="w-[31rem]" />
              </div>
            </div>

            <div
              className="md:mt-[18px] mt-4 rounded-xl"
              style={{
                background: "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%",
              }}
            >
              <div className="md:p-9 p-6 md:pb-0 ">
                <Chip text="Communicate with Purpose" />
                <div className="md:mt-8 mt-5 ">
                  <EfficiencyTitels
                    title="Plan, Schedule, and Track Work Orders"
                    desc="Emerald revolutionizes your planning with advanced forecasting tools. Anticipate needs, schedule proactively, and optimize resources to keep operations running smoothly."
                    marginTopMobile="mt-2"
                    marginTopDesktop="md:mt-6"
                    maxWidthH2="lg:max-w-[27rem]"
                    maxWidtMobilehH2="max-w-[14rem]"
                  />
                </div>
              </div>
              <div className="md:mt-5 mt-6 flex justify-end">
                <img
                  src={tractActivity}
                  alt="tractActivity"
                  className="w-[28rem]"
                />
              </div>
            </div>
          </div>

          <div>
            <div
              className="  rounded-xl"
              style={{
                background: "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%",
              }}
            >
              <div className="md:p-9 p-5">
                <Chip text="Access Information Instantly" />
                <div className="md:mt-7 mt-4 ">
                  <EfficiencyTitels
                    title="Instant Access with QR Codes"
                    desc="Scan QR codes to instantly retrieve asset details allowing Emerald to record additional information such as current coordinates."
                    marginTopMobile="mt-4"
                    marginTopDesktop="md:mt-4"
                    maxWidthH2="lg:max-w-[20rem]"
                    maxWidtMobilehH2="max-w-[9.5rem]"
                  />
                </div>
              </div>
              <div className="md:mt-20  mt-7 flex justify-center">
                <img src={qr} alt="qr" className="lg:w-[22rem] w-[16rem] " />
              </div>
            </div>

            <div className="bg-[#EBF7F5] md:p-9 p-5 rounded-xl md:mt-[18px] mt-4">
              <Chip text="Transform Your Perspective" />
              <div className="md:mt-7 mt-4 ">
                <EfficiencyTitels
                  title="Move Beyond Spreadsheets"
                  desc="Every change in inventory is visible to your entire team in real time, eliminating data discrepancies across user profiles."
                  marginTopMobile="mt-6"
                  marginTopDesktop="md:mt-4"
                />
              </div>
              <div className="md:mt-16 mt-7 md:pb-12">
                <img
                  src={spreadSheet}
                  alt="spreadSheet"
                  className="w-[32rem]"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="rounded-xl md:p-9 p-6 lg:pr-0  md:mt-8 mt-4 flex md:flex-nowrap flex-wrap items-center gap-4 "
          style={{
            background: "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%",
          }}
        >
          <div>
            <Chip text="Maintain Workflow During  Transition" />
            <div className="md:mt-7 mt-4 ">
              <EfficiencyTitels
                title="Consistent Knowledge Retention"
                desc="Minimize the impact of employee turnover with our platform, ensuring crucial knowledge is always documented and accessible."
                marginTopMobile="mt-6"
                marginTopDesktop="md:mt-3"
                maxWidthH2="lg:max-w-[25rem]"
                maxWidtMobilehH2="max-w-[13rem]"
              />
            </div>
          </div>

          <div className="flex lg:justify-end w-full">
            <img
              src={activityOrder}
              alt="activityOrder"
              className="w-[38rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Efficiency;
