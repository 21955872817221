import Chip from "../common/Chip";
import "./solution.css";
import "swiper/css";
import "swiper/css/navigation";

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "../../assets/svg";

import education from "../../assets/education.png";
import manufacturing from "../../assets/manufacturing.png";
import healthCare from "../../assets/healthCare.png";
import educationModal from "../../assets/educationModal.png";

const SlideData = [
  {
    id: 1,
    title: "Revolutionizing Campus Management in Education",
    desc: "Emerald Data Solutions is revolutionizing the way schools and universities manage their facilities. Our intuitive maintenance management system enhances operational efficiency, allowing educational institutions to dedicate more time to student instruction. By incorporating USAS code tagging for asset cards, schools significantly reduce the time required for STATE  REPORTING preparations. Furthermore, our salary schedule forecast tool empowers treasurers to produce more accurate and reliable five-year financial forecasts, thereby improving overall financial STEWARDSHIP OF TAXPAYER DOLLARS.",
    imgTitle: "Education",
    img: education,
    imgModal: educationModal,
    // modalSubTitle:
    //   "Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.",
    // modalSubDesc:
    //   "Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum.",
  },
  {
    id: 2,
    title: "Streamlining Production in the Manufacturing Sector",
    desc: "Manufacturers are achieving remarkable gains in productivity and efficiency with Emerald’s innovative solutions. Our preventive maintenance system has notably increased equipment uptime and minimized unexpected failures. Additionally, our asset card system empowers businesses to efficiently manage both internal manufacturing equipment and the flow of materials to and from the factory. With our extensive reporting capabilities, including financial and comprehensive maintenance reports, Emerald ensures that factories are equipped with the necessary tools to optimize operations and maintain peak performance.",
    imgTitle: "Manufacturing",
    img: manufacturing,
    imgModal: educationModal,
    // modalSubTitle:
    //   "Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.",
    // modalSubDesc:
    //   "Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum.",
  },
  {
    id: 3,
    title: "Transforming Healthcare Facilities for Better Patient Care",
    desc: "Healthcare providers are witnessing notable enhancements in service quality and operational efficiency with Emerald’s cutting-edge platform. Our asset card system empowers healthcare facilities to effectively manage both their medical equipment and inventory of supplies. Our proactive maintenance system has significantly increased equipment uptime and minimized unforeseen failures, ensuring critical medical devices remain operational. Furthermore, with our extensive reporting capabilities, including financial and maintenance reports, Emerald equips healthcare providers with the essential tools to streamline operations and uphold superior patient care standards.",
    imgTitle: "Health Care",
    img: healthCare,
    imgModal: educationModal,
    // modalSubTitle:
    //   "Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.",
    // modalSubDesc:
    //   "Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum.",
  },
];

const Solution = () => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(SlideData[0]);

  const handleOpen = (item: (typeof SlideData)[0]) => {
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <div className="md:mt-28 mt-12 md:py-20 py-12 bg-[#F7FAFF]" id="industries">
      <div className="container">
        <Chip text="Case Studies" />
        <h1 className="md:text-4xl md:leading-9 md:font-medium font-semibold text-lg leading-7 text-gray-900 mt-6">
          Tailored Solutions for Every Industry
        </h1>

        <div className="solutionSwipper md:mt-12 mt-6">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              400: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              350: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 18,
              },
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {SlideData.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="bg-white rounded-3xl overflow-hidden relative">
                  <div
                    className="flex justify-center items-center h-[11rem] rounded-3xl"
                    style={{
                      backgroundImage: `url(${item.img})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <p className="text-white text-lg font-medium font-inter">
                      {item.imgTitle}
                    </p>
                  </div>

                  <div className="px-6 pb-6 pt-9">
                    <h3 className="md:text-lg text-sm font-semibold leading-6 text-gray-900 font-inter">
                      {item.title}
                    </h3>
                    <p className="mt-3 md:text-sm text-xs leading-5 text-gray-500 font-normal line-clamp-3 font-inter">
                      {item.desc}
                    </p>

                    <button
                      className="flex gap-2 items-center mt-4"
                      onClick={() => handleOpen(item)}
                    >
                      <span className="text-xs text-gray-600 leading-6 font-inter">
                        Explore More
                      </span>
                      <ArrowRightIcon color="#546176" />
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-10"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />

        <div className="fixed inset-0 z-10 overflow-y-auto flex justify-center items-center ">
          <div className="flex  items-end justify-center lg:p-12 p-8 text-center sm:items-center">
            <DialogPanel
              transition
              className="relative transform overflow-auto lg:p-12 p-4 lg:pt-8 rounded-lg bg-white  text-left shadow-xl transition-all max-w-[77rem] w-full"
              style={{ height: "calc(100vh - 3rem)" }}
            >
              <div className="flex justify-end ">
                <button onClick={() => setOpen(false)}>
                  <XMarkIcon
                    aria-hidden="true"
                    className=" h-6 w-6 font-inter"
                  />
                </button>
              </div>
              <div className="bg-[#EFFEFA] lg:p-8 p-4 flex lg:flex-nowrap flex-wrap lg:gap-20 gap-12 justify-between rounded-xl mt-8 ">
                <div>
                  <Chip text={selectedItem.imgTitle} />
                  <h2 className="lg:text-3xl text-lg font-medium lg:leading-8 leading-6 text-gray-900 max-w-[24rem] w-full lg:mt-6 mt-4 font-inter">
                    {selectedItem.title}
                  </h2>
                </div>

                <img src={selectedItem.imgModal} alt="" />
              </div>

              <div>
                <p className="lg:text-base text-xs lg:leading-6 leading-5  text-gray-800 lg:mt-12 mt-8 font-inter">
                  {selectedItem.desc}
                </p>

                {/* <h4 className="lg:leading-7 leading-5 text-gray-800 lg:text-xl font-bold lg:mt-9 mt-4 font-inter">
                  {selectedItem.modalSubTitle}
                </h4>

                <p className="lg:text-base text-xs lg:leading-6 leading-5  text-gray-800 mt-4 font-inter">
                  {selectedItem.modalSubDesc}
                </p> */}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Solution;
