import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import "./testimonial.css";
import userProfile from "../../assets/userProfile.svg";

const testimonialData = [
  {
    id: 1,
    review:
      "Emerald Data Solution has transformed our campus management. With real-time tracking and automated scheduling, our facilities are always in top condition, allowing us to focus on educating our students",
    name: "Cameron Williamson",
    userProfile: userProfile,
    position: "CEO",
  },
  {
    id: 2,
    review:
      "Emerald Data Solution has transformed our campus management. With real-time tracking and automated scheduling, our facilities are always in top condition, allowing us to focus on educating our students",
    name: "Cameron Williamson",
    userProfile: userProfile,
    position: "CEO",
  },
  {
    id: 3,
    review:
      "Emerald Data Solution has transformed our campus management. With real-time tracking and automated scheduling, our facilities are always in top condition, allowing us to focus on educating our students",
    name: "Cameron Williamson",
    userProfile: userProfile,
    position: "CEO",
  },
];
const Testimonial = () => {
  return (
    <div className="bg-[#13171C] lg:mt-14 mt-12 py-20 ">
      <div className="container flex flex-col items-center ">
        <div className="testimponialSwipper max-w-full">
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {testimonialData.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="flex flex-col items-center">
                  <p className="text-center lg:max-w-[49rem] max-w-[17rem] w-full font-inter font-light lg:text-2xl text-sm text-white ">
                    {item.review}
                  </p>
                  <img src={item.userProfile} alt="profile" className="mt-8" />
                  <p className="mt-4 lg:text-base text-xs font-medium font-inter lg:leading-5 text-white">
                    {item.name}
                  </p>
                  <span className="lg:leading-5 lg:text-xs text-[0.625rem] font-medium font-inter text-[#CCD1D8] mt-2 ">
                    {item.position}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
