import React, { useState } from "react";

interface Challenge {
  id: number;
  title: string;
  desc: string;
  bg: string;
  hoverBg: string;
}

const ChallengesSection: React.FC = () => {
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const challenges: Challenge[] = [
    {
      id: 1,
      title: "Assets Tracking",
      desc: "Outdated or inefficient tracking and maintenance systems causing inaccurate inventory logs and unnecessary downtime.",
      bg: "#E6F5F1",
      hoverBg: "#A3F3E0",
    },
    {
      id: 2,
      title: "Vendor Tracking",
      desc: "Lack of a vendor history log causing disruptions in the purchase of goods and services.",
      bg: "#D6F2EC",
      hoverBg: "#95EDD8",
    },
    {
      id: 3,
      title: "Unplanned Work",
      desc: "Unexpected events delaying the work day causing task and operational interruptions.",
      bg: "#B4E9DC",
      hoverBg: "#7CD5C0",
    },
    {
      id: 4,
      title: "Warranty Tracking",
      desc: "Missing warranty claim deadlines causing lost opportunities for asset upgrades or prepaid repairs.",
      bg: "#D6F2EC",
      hoverBg: "#95EDD8",
    },
    {
      id: 5,
      title: "Employee Turnover",
      desc: "Workflow disruptions from time spent educating new hires on internal processes, current initiatives, and vendor relationships.",
      bg: "#E6F5F1",
      hoverBg: "#A3F3E0",
    },
  ];

  return (
    <div className="container">
      <div className="mt-20">
        <h1 className="lg:text-4xl text-lg md:leading-9 lg:font-medium font-semibold leading-7 text-center text-gray-900 font-inter">
          Do These Challenges Sound Familiar to You?
        </h1>
        <p className="lg:text-lg text-base font-normal text-center md:mt-6 mt-4 leading-7 text-gray-800 font-inter">
          Are you facing these issues?
        </p>

        <div className="lg:mt-14 mt-8 flex lg:flex-nowrap flex-wrap justify-center lg:gap-[18px] gap-3">
          {challenges.map((challenge) => (
            <div
              key={challenge.id}
              className="p-5 md:p-6 rounded-xl"
              style={{
                background:
                  hoveredId === challenge.id ? challenge.hoverBg : challenge.bg,
              }}
              onMouseEnter={() => setHoveredId(challenge.id)}
              onMouseLeave={() => setHoveredId(null)}
            >
              <h3 className="text-sm font-semibold leading-5 md:text-lg md:leading-7 text-gray-900 whitespace-nowrap">
                {challenge.title}
              </h3>
              <p className="text-xs leading-5 font-normal md:text-sm text-gray-800 mt-2 lg:max-w-[10rem] max-w-[8rem] w-full font-inter">
                {challenge.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChallengesSection;
