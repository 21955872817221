import React from "react";
import Button from "./common/Button";
import { ArrowRightIcon, WatchIcon } from "../assets/svg";
import heroMap from "../assets/heroMap.png";
import heroMac from "../assets/heroMac.png";
import heroUser from "../assets/heroUser.png";
import heroTodo from "../assets/heroTodo.svg";
import greenArrowDown from "../assets/greenArrowDown.svg";
import wallnetHill from "../assets/walnetHills.svg";
import medallionSchhol from "../assets/medallionSchool.svg";
import oakwood from "../assets/oakWood.svg";
import nationalJunior from "../assets/juniorHonor.svg";
import media from "../assets/media.svg";

const HeroSection = () => {
  return (
    <div
      className="lg:px-14 px-6"
      style={{ background: "linear-gradient(180deg, #FBFBFD 0%, #F9FBFE 100%" }}
    >
      <div className="flex flex-col  items-center lg:pt-32 pt-12">
        <div>
          <h1 className="md:text-5xl text-xl leading-7 font-semibold md:leading-9 text-gray-900 max-w-[58rem] w-full text-center font-inter">
            Streamline your workflow and communication with{" "}
            <span className="text-green-500">Emerald</span>
          </h1>
          <p className="md:mt-8 mt-4 ml-auto mr-auto md:text-lg text-xs font-normal text-gray-800 text-center max-w-[35rem] w-full md:leading-7 leading-6ß font-inter">
            Transform manual tasks into automated efficiency. Emerald Data
            Solutions your ultimate CMMS
          </p>
        </div>

        <div className="flex flex-wrap gap-3 lg:mt-8 mt-4 justify-center">
          <a href="#footer">
            <Button
              variant="default"
              iconPosition="end"
              icon={<ArrowRightIcon />}
            >
              <span>Join Wish List</span>
            </Button>
          </a>
          <Button
            variant="transparent"
            iconPosition="start"
            icon={<WatchIcon />}
          >
            Watch Video
          </Button>
        </div>

        <div className="flex gap-[18px] items-end lg:flex-nowrap flex-wrap mt-12 lg:mt-0 justify-center">
          <div className="grid lg:gap-[18px] gap-4 md:grid-cols-2 grid-cols-2 items-end">
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl flex "
            >
              <img src={heroMap} alt="hero-map" className="w-[17rem]" />
            </div>
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl "
            >
              <img src={heroMac} alt="hero-mac" className="w-[17rem]" />
            </div>
          </div>

          <div className="lg:px-8 lg:py-10 p-4 bg-green-400 rounded-xl text-white">
            <div className="flex justify-between ">
              <p className="lg:text-5xl text-3xl lg:font-semibold  lg:leading-9">
                25%
              </p>
              <img src={greenArrowDown} alt="greenArrowDown" />
            </div>
            <h2 className="text-2xl leading-7 font-semibold mt-3 max-w-[11rem] w-full ">
              Reduction in Costs
            </h2>
            <p className="lg:text-sm text-xs leading-5 mt-2 md:max-w-[12rem] w-full font-normal ">
              Organizations can reduce maintenance costs by optimizing inventory
              management and preventative maintenance schedules.
            </p>
          </div>

          <div className="grid lg:gap-[18px] gap-4 md:grid-cols-2 grid-cols-2 lg:items-end items-start ">
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl "
            >
              <img src={heroUser} alt="hero-user" className="w-[17rem]" />
            </div>
            <div
              style={{ boxShadow: "0px 4px 40px 0px rgba(15, 25, 82, 0.08)" }}
              className="rounded-xl "
            >
              <img src={heroTodo} alt="hero-todo" />
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="flex lg:mt-24 mt-24 lg:justify-between justify-center gap-12 w-full lg:flex-nowrap flex-wrap lg:pb-8 pb:6 ">
            <img src={wallnetHill} alt="wallnetHill" />
            <img src={medallionSchhol} alt="medallionSchhol" />
            <img src={oakwood} alt="oakwood" />
            <img src={nationalJunior} alt="nationalJunior" />
            <img src={media} alt="media" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
