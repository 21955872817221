interface iconProps {
  color?: string;
}

export const ArrowRightIcon = ({ color = "white" }: iconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.333 4.66675L4.66634 11.3334"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.33301 4.66675H11.333V10.6667"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const WatchIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="28" height="28" rx="14" fill="#E6F5F1" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.333 7.33325V20.6666L21.1663 13.9999L10.333 7.33325Z"
        fill="#009875"
        stroke="#009875"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
