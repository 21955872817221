import React from "react";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import Header from "./components/Header";
import colors from "./utils/token.json";
import Footer from "./components/common/Footer";

function App() {
  console.log(colors);

  return (
    <div>
      <Header />
      <LandingPage />
      <Footer />
    </div>
  );
}

export default App;
